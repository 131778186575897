* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
:root {
  --series-count-background-color: #678696;
}
.ThumbnailEntry {
  cursor: pointer;
  display: table;
}
.ThumbnailEntry .p-x-1 {
  padding: 0 1rem;
}
.ThumbnailEntry .alt-image-text {
  align-items: center;
  justify-content: center;
  background-color: var(--primary-background-color);
  box-shadow: inset 0 0 0 1px var(--ui-border-color-dark);
  border: 5px solid transparent;
  border-radius: 12px;
  height: 135px;
  margin: 0 auto;
  padding: 5px;
  position: relative;
  transition: var(--sidebar-transition);
  width: 217px;
  display: flex;
}
.ThumbnailEntry .alt-image-text h1 {
  text-align: center;
  color: var(--text-primary-color);
}
.ThumbnailEntry .series-details {
  display: flex;
  flex-direction: row;
  color: var(--text-primary-color);
  font-size: 14px;
  line-height: 1.3em;
  margin-top: 5px;
  margin-left: 1rem;
  margin-right: 0.5rem;
  max-width: 217px;
  min-height: 36px;
  position: relative;
  word-wrap: break-word;
}
.ThumbnailEntry .series-details .series-description {
  flex-grow: 1;
}
.ThumbnailEntry .series-details.info-only .series-description {
  display: none;
}
.ThumbnailEntry .series-details.info-only .series-information {
  display: flex;
  flex-grow: 1;
  float: none;
  max-width: none;
  padding-right: 0;
}
.ThumbnailEntry .series-details.info-only .series-information .item {
  flex: 1;
  text-align: center;
}
.ThumbnailEntry .series-details.info-only .series-information .item .icon,
.ThumbnailEntry .series-details.info-only .series-information .item .value {
  display: inline;
  float: none;
  line-height: 25px;
}
.ThumbnailEntry .series-details.info-only .series-information .item .value {
  margin-left: 0;
  width: auto;
}
.ThumbnailEntry .series-information {
  padding-right: 4px;
  max-width: 50px;
}
.ThumbnailEntry .series-information .item-frames .icon {
  height: 18px;
}
.ThumbnailEntry .series-information .value {
  color: var(--text-secondary-color);
  display: inline-block;
  float: right;
  font-size: 12px;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 15px);
}
.ThumbnailEntry .series-information .icon {
  color: var(--active-color);
  display: inline-block;
  float: left;
  font-size: 10px;
  font-weight: 900;
  text-align: right;
  width: 11px;
}
.ThumbnailEntry .series-information .icon div {
  background-color: var(--series-count-background-color);
  margin-top: 6px;
  position: relative;
}
.ThumbnailEntry .series-information .icon div:after {
  background-color: var(--active-color);
  box-shadow: 1px 1px rgba(0,0,0,0.115);
  left: -4px;
  position: absolute;
  top: -4px;
}
.ThumbnailEntry .series-information .icon div,
.ThumbnailEntry .series-information .icon div:after {
  border: 0px solid var(--series-count-background-color);
  content: '';
  display: inline-block;
  height: 11px;
  width: 11px;
}
.image-thumbnail-clone {
  z-index: 100000;
  pointer-events: none;
}
.image-thumbnail-clone:hover {
  border-color: var(--hover-color);
  box-shadow: none;
}
